import React from 'react'

const Features = (props) => {


     const featres = [
         {
            fIcon:'fi flaticon-badge',
            title:'Advogados Experientes',
            des:'Profissionais especializados em todas as áreas do direito.',
         },
         {
            fIcon:'fi flaticon-diary',
            title:'Transparência',
            des:'Nossa transparência é reconhecida a mais de 40 anos.',
         },
         {
            fIcon:'fi flaticon-support',
            title:'Atendimento Exclusivo',
            des:'Tenha acesso direto a todo nosso corpo jurídico.',
         },
         
     ]


    return(
        <section className={`wpo-features-section section-padding  ${props.featuresClass}`}>
            <div className="container">
                <div className="wpo-features-wrapper">
                    <div className="row">
                        {featres.map((featres, fitem) => (
                            <div className="col-lg-4 col-md-6 col-12" key={fitem}>
                                <div className="wpo-features-item">
                                    <div className="wpo-features-icon">
                                        <div className="icon">
                                            <i className={featres.fIcon}></i>
                                        </div>
                                    </div>
                                    <div className="wpo-features-text">
                                        <h2>{featres.title}</h2>
                                        <p>{featres.des}</p>
                                    </div>
                                    <div className="visible-icon">
                                        <i className={featres.fIcon}></i>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Features;