import simg from '/public/images/service/civel.png'
import simg2 from '/public/images/service/familia.png'
import simg3 from '/public/images/service/trabalhista.png'
import simg4 from '/public/images/service/previdenciario.png'
import simg5 from '/public/images/service/criminal.png'

const Services = [
    {
        Id: '1',
        sImg: simg,
        sTitle: 'Cível',
        description: 'O Direito Civil é uma área do direito que trata das relações entre indivíduos e entidades privadas. É um dos ramos mais abrangentes do direito, abordando uma variedade de temas, incluindo direitos e obrigações das pessoas, propriedade, contratos, responsabilidade civil, família, sucessões, entre outros.'
    },
    {
        Id: '2',
        sImg: simg2,
        sTitle: 'Família',
        description: 'O Direito de Família é uma área do direito que trata das relações familiares e dos direitos e obrigações que surgem dessas relações. Ele abrange uma variedade de questões relacionadas à formação, estrutura e dissolução das famílias.'
    },
    {
        Id: '3',
        sImg: simg3,
        sTitle: 'Trabalhista',
        description: 'O Direito Trabalhista é uma área do direito que regula as relações entre empregadores e trabalhadores, bem como as condições de trabalho e os direitos dos empregados. Seu principal objetivo é proteger os trabalhadores, garantindo condições justas e dignas de trabalho e promovendo relações laborais equilibradas.'
    },
    {
        Id: '4',
        sImg: simg4,
        sTitle: 'Previdenciário',
        description: 'O Direito Previdenciário é uma área do direito que trata dos direitos relacionados à previdência social, ou seja, ao sistema de proteção social que garante benefícios aos trabalhadores e seus dependentes em situações de incapacidade, idade avançada, desemprego involuntário, maternidade, entre outras eventualidades.'
    },
    {
        Id: '5',
        sImg: simg5,
        sTitle: 'Criminal',
        description: 'O Direito Criminal, também conhecido como Direito Penal, é uma área do direito que trata das normas e dos princípios que regulam o sistema jurídico relacionado aos crimes, suas penalidades e os procedimentos criminais. Ele tem como objetivo principal proteger a sociedade contra condutas que são consideradas lesivas, garantindo a ordem pública e a segurança dos cidadãos.'
    }
]
export default Services;