import React from 'react'
import Link from 'next/link';


const HeaderTopbar = () => {
    return(	
        <div className="topbar">
            <div className="container-fluid">
                <div className="row">
                    <div className="col col-md-12 col-sm-12 col-12">
                        <div className="contact-intro">
                            <ul>
                                <li><i className="fi flaticon-phone-call"></i><a href='tel:+551150731765'> (11) 5073-1765</a></li>
                                <li>|</li>
                                <li><i className="fi flaticon-email"></i><a href='mailto:contato@dacruzadvogados.com.br'> contato@dacruzadvogados.com.br</a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default HeaderTopbar;