import React from 'react'
import Link from 'next/link';
import Logo from '/public/images/logo-branco.png'
import Services from '../../pages/api/service';

import ins1 from '/public/images/ft-icon/1.png'
import ins2 from '/public/images/ft-icon/2.png'
import ins3 from '/public/images/ft-icon/3.png'
import ins4 from '/public/images/ft-icon/4.png'
import Image from 'next/image';


const Footer = (props) => {

    const ClickHandler = () => {
        window.scrollTo(10, 0);
    }

    return (
        <footer className="wpo-site-footer">
            <div className="wpo-upper-footer">
                <div className="container">
                    <div className="row">
                        <div className="col col-lg-4 col-md-4 col-sm-12 col-12">
                            <div className="widget about-widget">
                                <div className="logo widget-title">
                                    <Image src={Logo} alt="logo" />
                                </div>
                                <p>Na DaCruz Advogados, estamos comprometidos em oferecer soluções jurídicas eficientes e personalizadas. Protegemos seus direitos e defendemos seus interesses. Entre em contato conosco e descubra como podemos ajudá-lo.</p>
                            </div>
                        </div>
                        <div className="col col-lg-4 col-md-4 col-sm-12 col-12">
                            <div className="widget link-widget">
                                <div className="widget-title">
                                    <h3>Nosso Serviços</h3>
                                </div>
                                <ul>
                                    {Services.slice(0, 5).map((service, Sitem) => (
                                        <li key={Sitem}>{service.sTitle}</li>
                                    ))}
                                </ul>
                            </div>
                        </div>

                        <div className="col col-lg-4 col-md-4 col-sm-12 col-12">
                            <div className="widget social-widget">
                                <div className="widget-title">
                                    <h3>Contatos</h3>
                                </div>
                                <ul>
                                    <li><a href='tel:+551150731765'> (11) 5073-1765</a></li>
                                    <li><a href='mailto:contato@dacruzadvogados.com.br'> contato@dacruzadvogados.com.br</a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="wpo-lower-footer">
                <div className="container">
                    <div className="row">
                        <div className="col col-xs-12">
                            <p className="copyright"> Copyright &copy; {`${new Date().getFullYear()}`} DaCruz Advogados. Todos os direitos reservados.</p>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    )
}

export default Footer;