import React, { useState } from 'react'
import SimpleReactValidator from 'simple-react-validator';
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import { Bounce, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const ContactForm = () => {
    const { executeRecaptcha } = useGoogleReCaptcha();
    const [forms, setForms] = useState({
        disabled: false,
        name: '',
        email: '',
        subject: '',
        phone: '',
        message: ''
    });
    const [validator] = useState(new SimpleReactValidator({
        className: 'errorMessage',
        messages: {
            email: 'informe um email válido',
            default: 'campo obrigatório'
        }
    }));
    const changeHandler = e => {
        setForms({ ...forms, [e.target.name]: e.target.value })
        if (validator.allValid()) {
            validator.hideMessages();
        } else {
            validator.showMessages();
        }
    };
    const submitHandler = async e => {
        e.preventDefault();
        setForms({ ...forms, disabled: true });
        if (validator.allValid()) {
            const gRecaptchaToken = await executeRecaptcha("submitForm");
            fetch('/api/sendEmail', {
                method: 'POST',
                body: JSON.stringify({ ...forms, gRecaptchaToken })
            })
                .then(response => {
                    if (response.status === 200) {
                        toast.success('Email enviado com sucesso, em breve um de nossos especialistas entrará em contato!', {
                            position: "top-left",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: "colored",
                            transition: Bounce,
                        });
                        validator.hideMessages();
                        setForms({
                            disabled: true,
                            name: '',
                            email: '',
                            subject: '',
                            phone: '',
                            message: ''
                        });
                    }
                    else {
                        toast.error('Erro ao enviar email, tente novamente!', {
                            position: "top-left",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: "colored",
                            transition: Bounce,
                        });
                        setForms({ ...forms, disabled: false });
                    }
                })
                .catch(error => {
                    console.log(error);
                    toast.error(error.toString(), {
                        position: "top-left",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "colored",
                        transition: Bounce,
                    });
                    setForms({ ...forms, disabled: false });
                });
        } else {
            setForms({ ...forms });
            validator.showMessages();
        }
    };

    return (
        <section className="wpo-contact-section section-padding" id='contact-form'>
            <div className="container">
                <div className="wpo-contact-section-wrapper">
                    <div className="row">
                        <div className="col-lg-6 col-md-12 col-12">
                            <div className="wpo-contact-form-area">
                                <h2>Consulte-nos</h2>
                                <form onSubmit={(e) => submitHandler(e)} className="contact-validation-active" >
                                    <div className="row">
                                        <div className="form-field">
                                            <input
                                                value={forms.name}
                                                disabled={forms.disabled}
                                                className="form-control"
                                                type="text"
                                                name="name"
                                                onBlur={(e) => changeHandler(e)}
                                                onChange={(e) => changeHandler(e)}
                                                placeholder="Seu Name" />
                                            {validator.message('name', forms.name, 'required|alpha_space')}
                                        </div>
                                        <div className="form-field">
                                            <input
                                                value={forms.phone}
                                                disabled={forms.disabled}
                                                className="form-control"
                                                type="number"
                                                name="phone"
                                                onBlur={(e) => changeHandler(e)}
                                                onChange={(e) => changeHandler(e)}
                                                placeholder="Seu Telefone" />
                                            {validator.message('phone', forms.phone, 'required|numeric')}
                                        </div>
                                        <div className="form-field">
                                            <input
                                                value={forms.email}
                                                disabled={forms.disabled}
                                                className="form-control"
                                                type="email"
                                                name="email"
                                                onBlur={(e) => changeHandler(e)}
                                                onChange={(e) => changeHandler(e)}
                                                placeholder="Seu Email" />
                                            {validator.message('email', forms.email, 'required|email')}
                                        </div>
                                        <div className="form-field">
                                            <select
                                                onBlur={(e) => changeHandler(e)}
                                                onChange={(e) => changeHandler(e)}
                                                value={forms.subject}
                                                disabled={forms.disabled}
                                                aria-label="Escolha a Área"
                                                className="form-control"
                                                type="text"
                                                name="subject">
                                                <option value={''}>Escolha a Área</option>
                                                <option value={'Cível'}>Cível</option>
                                                <option value={'Família'}>Família</option>
                                                <option value={'Trabalhista'}>Trabalhista</option>
                                                <option value={'Previdenciário'}>Previdenciário</option>
                                                <option value={'Criminal'}>Criminal</option>
                                            </select>
                                            {validator.message('subject', forms.subject, 'required')}
                                        </div>
                                        <div className="form-field">
                                            <textarea
                                                onBlur={(e) => changeHandler(e)}
                                                onChange={(e) => changeHandler(e)}
                                                className="form-control"
                                                value={forms.message}
                                                disabled={forms.disabled}
                                                type="text"
                                                name="message"
                                                placeholder="Mensagem">
                                            </textarea>
                                            {validator.message('message', forms.message, 'required')}
                                        </div>
                                    </div>
                                    <div className="submit-area">
                                        <button type="submit" className="theme-btn" disabled={forms.disabled}> Enviar Agora</button>
                                    </div>
                                </form >
                                <div className="border-style"></div>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12 col-12">
                            <div className="wpo-contact-content">
                                <h2>Protegendo seus direitos, defendendo seus interesses</h2>
                                <div className="wpo-contact-content-inner">
                                    <p>A DaCruz Advogados é uma firma jurídica dedicada a oferecer soluções eficazes para uma variedade de questões legais. Com uma equipe experiente e comprometida, proporcionamos orientação personalizada em áreas como direito civil, de família, trabalho, previdenciário e criminal. Valorizamos a transparência, a comunicação clara e o relacionamento de confiança com nossos clientes. Conte conosco para representar e defender seus interesses com dedicação e comprometimento. Entre em contato e descubra como podemos ajudá-lo.</p>
                                    <p>Nosso compromisso com a excelência profissional e a ética nos permite atuar de forma assertiva em diversas áreas do direito, incluindo direito civil, direito de família, direito do trabalho, direito previdenciário, direito criminal, entre outros. Estamos sempre atualizados com as mudanças na legislação e jurisprudência, garantindo assim um serviço de alta qualidade e atualizado para nossos clientes.</p>
                                    <div className="signeture">
                                        <h3>José Eduardo da Cruz Jr.</h3>
                                        <p>Founder & CEO</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="visible-text">
                        <span>C</span>
                    </div>
                </div>
            </div>
        </section>

    )
}

export default ContactForm;