import React from "react";
import Slider from "react-slick";

const Testimonial = () => {

    var settings = {
        dots: true,
        arrows: false,
        speed: 1000,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
    };

    const testimonial = [
        {
            Des: "Contratei a DaCruz Advogados para uma disputa contratual e fiquei muito satisfeito. Eles foram incansáveis em encontrar a melhor solução, com habilidades de negociação excepcionais e expertise jurídica sólida. Agradeço pelo excelente serviço.”",
            Title: 'Marcelo Williamson',
            Sub: "CEO & Founder",
        },
        {
            Des: "“Confiei na DaCruz Advogados para questões legais do meu negócio e não me arrependo. Eles ofereceram suporte abrangente e personalizado, com orientações precisas e estratégias sob medida. Recomendo fortemente seus serviços.”",
            Title: 'Felipe Dutra Jr.',
            Sub: "CTO & Founder",
        },
        {
            Des: "“Estou muito satisfeita com a DaCruz Advogados. Eles foram excepcionais no meu caso de divórcio, com orientação clara e eficaz. A equipe mostrou profundo conhecimento jurídico e compromisso genuíno. Recomendo a DaCruz Advogados a todos.”",
            Title: 'Carla Pereira Dantas',
            Sub: "Analista Contábil",
        }
    ]
    return (
        <section className="wpo-testimonials-section section-padding">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-5 col-12">
                        <div className="wpo-section-title">
                            <h2>O que nossos clientes dizem sobre nós?</h2>
                            <p>Trabalhamos de forma a mantermos uma experiência incrível para nossos clientes.</p>
                        </div>
                    </div>
                    <div className="col-lg-7 col-12">
                        <div className="wpo-testimonial-wrap owl-carousel">
                            <Slider {...settings}>
                                {testimonial.map((tesmnl, tsm) => (
                                    <div className="wpo-testimonial-item" key={tsm}>
                                        <p>{tesmnl.Des}</p>
                                        <div className="wpo-testimonial-info">
                                            <div className="wpo-testimonial-info-text">
                                                <p>{tesmnl.Title}</p>
                                                <span>{tesmnl.Sub}</span>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </Slider>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Testimonial;