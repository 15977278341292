import React, { Fragment } from 'react';
import Navbar from '../components/Navbar'
import Hero from '../components/hero'
import Features from '../components/Features'
import Practice from '../components/Practice'
import Testimonial from '../components/Testimonial'
import ContactForm from '../components/Consulting';
import Footer from '../components/footer'
import Scrollbar from '../components/scrollbar'
import Logo from '/public/images/logo.png'
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

const HomePage = () => {
    return (
        <GoogleReCaptchaProvider
            reCaptchaKey={process.env.NEXT_PUBLIC_RECAPTCHA_SITE_KEY}
            scriptProps={{
                async: false,
                defer: false,
                appendTo: "head",
                nonce: undefined,
            }}
        >
            <Fragment>
                <Navbar Logo={Logo} />
                <Hero />
                <Features />
                <Practice />
                <Testimonial />
                <ContactForm />
                <Footer />
                <Scrollbar />
            </Fragment>
        </GoogleReCaptchaProvider>
    )
};
export default HomePage;