import React, { useState } from 'react';
import { TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap';
import classnames from 'classnames';
import Link from 'next/link';
import Services from '../../pages/api/service';
import Image from 'next/image';


const Practice = (props) => {

    const [activeTab, setActiveTab] = useState('1');

    const toggle = tab => {
        if (activeTab !== tab) setActiveTab(tab);
    }

    const ClickHandler = () => {
        window.scrollTo(10, 0);
    }



    const Tabs = [
        {
            Id: '1',
            tabItem: 'Cível',
            tabNumber: '01'
        },
        {
            Id: '2',
            tabItem: 'Família',
            tabNumber: '02'
        },
        {
            Id: '3',
            tabItem: 'Trabalhista',
            tabNumber: '03'
        },
        {
            Id: '4',
            tabItem: 'Previdenciário',
            tabNumber: '04'
        },
        {
            Id: '5',
            tabItem: 'Criminal',
            tabNumber: '05'
        }
    ]



    return (

        <section className="wpo-service-section section-padding" id='expertise-areas'>
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-12">
                        <div className="wpo-section-title">
                            <h2>Áreas de Atuação do Nosso Escritório</h2>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col col-lg-4 col-12 service-thumbs">
                        <Nav tabs className="service-thumbs-outer">
                            {Tabs.map((tab, titem) => (
                                <NavItem key={titem}>
                                    <NavLink
                                        className={`service-thumb ${classnames({ active: activeTab === tab.Id })}`}
                                        href={`#${tab.tabItem}`}
                                        onClick={() => { toggle(tab.Id); }}
                                    >
                                        {tab.tabItem}
                                        <span className="number">{tab.tabNumber}</span>
                                    </NavLink>
                                </NavItem>
                            ))}
                        </Nav>
                    </div>
                    <div className="col col-lg-8 col-12 service-content">
                        <div className="service-content-outer">
                            <TabContent activeTab={activeTab}>
                                {Services.map((service, Sitem) => (
                                    <TabPane tabId={service.Id} key={Sitem}>
                                        <div className="service-data">
                                            <div className="service-data-img">
                                                <Image src={service.sImg} alt="" />
                                                <div className="service-data-text">
                                                    <h3>{service.sTitle}</h3>
                                                    <p>{service.description}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </TabPane>
                                ))}
                            </TabContent>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}


export default Practice;