import React, { Component } from 'react'
import Link from 'next/link';
import HeaderTopbar from '../../components/HeaderTopbar'
import MobileMenu from '../../components/MobileMenu'
import Image from 'next/image';
import { connect } from "react-redux";
import AnchorLink from 'react-anchor-link-smooth-scroll';

class Header extends Component {

    state = {
        isSearchShow: false,
        isCartShow: false,
    }

    searchHandler = () => {
        this.setState({
            isSearchShow: !this.state.isSearchShow
        })
    }
    cartHandler = () => {
        this.setState({
            isCartShow: !this.state.isCartShow
        })
    }

    render() {
        const ClickHandler = () => {
            window.scrollTo(10, 0);
        }

        return (
            <header id="header" className={this.props.topbarNone}>
                <HeaderTopbar />
                <div className={`wpo-site-header ${this.props.hclass}`}>
                    <nav className="navigation navbar navbar-expand-lg navbar-light">
                        <div className="container-fluid">
                            <div className="row align-items-center">
                                <div className="col-lg-3 col-md-3 col-3 d-lg-none dl-block">
                                    <div className="mobail-menu">
                                        <MobileMenu />
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-6 col-6">
                                    <div className="navbar-header">
                                        <Link onClick={ClickHandler} className="navbar-brand" href="/">
                                            <Image src={this.props.Logo} alt="DaCruz Advogados" />
                                        </Link>
                                    </div>
                                </div>
                                <div className="col-lg-9 col-md-1 col-1">
                                    <div id="navbar" className="collapse navbar-collapse navigation-holder">
                                        <button className="menu-close"><i className="ti-close"></i></button>
                                        <ul className="nav navbar-nav mb-2 mb-lg-0">
                                            <li className="menu-item-has-children">
                                                <AnchorLink href='#__next'>Home</AnchorLink>
                                            </li>
                                            <li className="menu-item-has-children">
                                                <AnchorLink href='#expertise-areas'>Áreas de Atuaçãos</AnchorLink>
                                            </li>
                                            <li className="menu-item-has-children">
                                                <AnchorLink href='#contact-form'>Contato</AnchorLink>
                                            </li>
                                        </ul>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </nav>
                </div>
            </header>
        )
    }
}

export default connect()(Header);